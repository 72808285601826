var riot = require('riot');
module.exports = 
riot.tag2('broadbean', '<h2>Broadbean Jobs</h2> <div if="{jobs.length &gt; 0}" class="bbjobs"> <div each="{job in jobs}" class="bbjob"> <table style="margin-bottom:30px" class="table table-condensed table-bordered"> <tr> <td>contact_name:</td> <td>{job.contact_name}</td> </tr> <tr> <td>contact_email:</td> <td>{job.contact_email}</td> </tr> <tr> <td>contact_telephone:</td> <td>{job.contact_telephone}</td> </tr> <tr> <td>contact_url:</td> <td>{job.contact_url}</td> </tr> <tr> <td>days_to_advertise:</td> <td>{job.days_to_advertise}</td> </tr> <tr> <td>application_email:</td> <td>{job.application_email}</td> </tr> <tr> <td>application_url:</td> <td>{job.application_url}</td> </tr> <tr> <td>job_reference:</td> <td>{job.job_reference}</td> </tr> <tr> <td>job_title:</td> <td>{job.job_title}</td> </tr> <tr> <td>job_type:</td> <td>{job.job_type}</td> </tr> <tr> <td>job_duration:</td> <td>{job.job_duration}</td> </tr> <tr> <td>job_startdate:</td> <td>{job.job_startdate}</td> </tr> <tr> <td>job_skills:</td> <td>{job.job_skills}</td> </tr> <tr> <td>job_description:</td> <td riot-tag="raw" content="{job.job_description}"></td> </tr> <tr> <td>job_location:</td> <td>{job.job_location}</td> </tr> <tr> <td>job_industry:</td> <td>{job.job_industry}</td> </tr> <tr> <td>salary_currency:</td> <td>{job.salary_currency}</td> </tr> <tr> <td>salary_from:</td> <td>{job.salary_from}</td> </tr> <tr> <td>salary_to:</td> <td>{job.salary_to}</td> </tr> <tr> <td>salary_per:</td> <td>{job.salary_per}</td> </tr> <tr> <td>salary_benefits:</td> <td>{job.salary_benefits}</td> </tr> <tr> <td>salary:</td> <td>{job.salary}</td> </tr> </table> </div> </div> <div if="{!jobs}"> <h3>Sorry, no job results were found</h3> </div>', '', '', function(opts) {
var bbApi, resource;

resource = require('riot-kit/utils/apiResource.coffee');

bbApi = new resource('/duocms/api/broadbean', true);

this.on('mount', function() {
  return bbApi.get({}, (function(_this) {
    return function(data) {
      if (data) {
        _this.jobs = data;
        return _this.update();
      }
    };
  })(this));
});
});
var riot = require('riot');
module.exports = 
riot.tag2('duomap', '<gmap name="gmap" postcode="WA3 3GR" title="PHS Compliance" zoom="15" hidemarker="{true}" key="AIzaSyA6IwdPV1BqaNFJsnk6gM2CeKIhtuJIhfI" primary="#11a8a5" secondary="#44565e" stylearray="{opts.mapstyle}" class="mymap"> <div>Adele Carr,<br>St James Business Centre,<br>Wilderspool Causeway,<br>Warrington<br>WA4 6PS </div> </gmap> <gmap-markers markers="{locations}" linkedmap="gmap" icon="/images/assets/mapicon.png"></gmap-markers>', '', '', function(opts) {
this.locs = this.opts.locations || {};

this.locations = [];

if (this.locs[0]) {
  this.locarr = this.locs.split("\n");
  this.locarr.map((function(_this) {
    return function(loc, id) {
      var regex;
      regex = /[a-z]{1,2}[0-9]{1,2} ?[0-9]{1,2}[a-z]{1,2}/gi;
      return _this.locations.push({
        "postcode": loc.match(regex).toString(),
        "name": loc
      });
    };
  })(this));
}
});
var riot = require('riot');
module.exports = 
riot.tag2('locations', '<ul class="locationslist list-unstyled"> <li class="hidden-xs"><a onclick="{toggle}">More locations&nbsp;<i class="fa fa-angle-down"></i></a></li> <li class="visible-xs"> <table class="teltable"> <tr> <th>Warrington</th> <td class="text-right"><a href="tel:+44 01925 636473">+44 01925 636473</a></td> <td><a onclick="{toggle}" style="float:right"><i class="fa fa-angle-down"></i></a></td> </tr> </table> </li> <li class="sublocs"> <table> <tr> <th>Liverpool</th> <td class="text-right"><a href="tel:+44 0151 229 1019">+44 0151 229 1019</a></td> </tr> <tr> <th>Chester</th> <td class="text-right"><a href="tel:+44 01244 394255">+44 01244 394255</a></td> </tr> <tr> <th>Manchester</th> <td class="text-right"><a href="tel:+44 0161 258 0922">+44 0161 258 0922</a></td> </tr> <tr> <th>Lancashire</th> <td class="text-right"><a href="tel:+44 01772 914121">+44 01772 914121</a></td> </tr> </table> </li> </ul>', '.locationslist { position: relative; height: 40px; } .locationslist .teltable th, .locationslist .teltable td { padding: 0px 2px; white-space: nowrap; } .locationslist .sublocs { background: #fff; position: absolute; z-index: 10; top: 40px; right: 0; height: 0; max-height: 0; -webkit-transition: max-height ease 0.5s; -moz-transition: max-height ease 0.5s; -o-transition: max-height ease 0.5s; -ms-transition: max-height ease 0.5s; transition: max-height ease 0.5s; overflow: hidden; line-height: 1.2; -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2); box-shadow: 0 5px 10px rgba(0,0,0,0.2); } .locationslist .sublocs th, .locationslist .sublocs td { padding: 5px 10px; white-space: nowrap; } .locationslist.telshowing .sublocs { height: auto; max-height: 200px; }', '', function(opts) {
this.show = false;

this.toggle = (function(_this) {
  return function() {
    _this.show = !_this.show;
    if (_this.show) {
      document.querySelector('.locationslist').classList.add('telshowing');
    } else {
      document.querySelector('.locationslist').classList.remove('telshowing');
    }
    return _this.update();
  };
})(this);
});